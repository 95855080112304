import React, { lazy, Suspense } from 'react';
import { useInView } from 'react-hook-inview';

import { IonContent, IonPage } from '@ionic/react';

import LoadingSuspense from '@/components/atoms/LoadingSuspense.tsx';
import Footer from '@/components/molecules/Footer.tsx';
import Header from '@/components/molecules/Header.tsx';
import { PageMeta } from '@/contexts/PageMetaContext.tsx';
import HomeSectionHowItWorks from '@/modules/home/components/HomeSectionHowItWorks.tsx';
import HomeSectionSearch from '@/modules/home/components/HomeSectionSearch.tsx';

const HomeSectionSellMore = lazy(() => import('@/modules/home/components/HomeSectionSellMore.tsx'));
const HomeSectionPromote = lazy(() => import('@/modules/home/components/HomeSectionPromote.tsx'));

export default function HomePage() {
  const [ref, isVisible] = useInView({
    threshold: 1,
    unobserveOnEnter: true,
  });

  // <div ref={ref}>{isVisible ? 'Hello World!' : ''}</div>

  return (
    <IonPage>
      <PageMeta subTitle="" title="" />

      <Header isFullWidth isLogo isWhite />

      <IonContent className="root-bg-white">
        <div className="flex min-h-full flex-col gap-4 lg:gap-12">
          <HomeSectionSearch />
          <HomeSectionHowItWorks />

          <div className="min-h-40" ref={ref}>
            {isVisible && (
              <Suspense fallback={<LoadingSuspense />}>
                <HomeSectionSellMore />
              </Suspense>
            )}
          </div>

          {isVisible && (
            <Suspense fallback={<LoadingSuspense />}>
              <HomeSectionPromote />
            </Suspense>
          )}

          <Footer />
        </div>
      </IonContent>
    </IonPage>
  );
}
